import { useEffect, useRef, useState } from "react";
import homeStyle from './home.module.css'
import video1 from "../../assets/videos/video1.mp4";
import { useTranslation } from "react-i18next";


export function Video(){
  const { t } = useTranslation();

   const videoRef = useRef(null);
   const containerRef = useRef(null);
   const hasUserInteracted = useRef(false);
   const [isMuted, setIsMuted] = useState(true);

   useEffect(() => {
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (videoRef.current) {
            if (entry.isIntersecting) {
              videoRef.current.play().catch((error) => {
                console.error("Failed to play the video:", error);
              });
            } else {
              videoRef.current.pause();
            }
          }
        });
      };
  
      const observerOptions = {
        threshold: 0.5,
      };
  
      const observer = new IntersectionObserver(handleIntersection, observerOptions);
  
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
  
      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }, []);
  
    const handleUnmute = () => {
      if (videoRef.current) {
        setIsMuted(false);
        videoRef.current.muted = false;
        videoRef.current.play().catch((error) => {
          console.error("Failed to play the video:", error);
        });
      }
      if (isMuted==false) {
        setIsMuted(true);
      }
    };


   return<>
   <section className="container-fluid mt-5 bg-lightMe">
  <div className="row mx-1 mx-md-5 pb-2">
    <div className="col-md-4 d-flex justify-content-center align-items-center text-center text-md-start ">
      <div>
        <div className="pb-2 ">
          <h1 className="BigshotOneFont font-monospace fw-bold lh-base">{t("whisked")}</h1>
          <div className="w-50 border-bottom border-dark mx-auto mx-md-0"></div> {/* Added mx-auto */}
        </div>
        <div className="py-5">
          <p className="font-monospace fw-bold">{t("Embark")}</p>
        </div>
      </div>
    </div>
    <div className="col-md-8 pb-5 pb-md-0">
      <div className={`vh-md-100 ${homeStyle.positionRelative}`}>
        <svg className={homeStyle.svgOverlay1} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160">
          <path fill="#f3f4f5" fillOpacity="1" d="M0,96L480,160L960,128L1440,160L1440,0L960,0L480,0L0,0Z"></path>
        </svg>
        <div ref={containerRef} className="w-100 h-100 position-relative">
          <video ref={videoRef} className="w-100 h-me" muted={isMuted} controls>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {isMuted && (
            <div
              onClick={handleUnmute}
              className="text-white position-absolute top-70 end-3 translate-middle"
            >
              <i className="fa-solid fa-volume-xmark cursor-pointer"></i>
            </div>
          )}
          {!isMuted && (
            <div
              onClick={handleUnmute}
              className="text-white position-absolute top-70 end-3 translate-middle"
            >
              <i className="fa-solid fa-volume-high cursor-pointer"></i>
            </div>
          )}
        </div>
        <svg className={homeStyle.svgOverlay2} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fillOpacity="1" d="M0,64L480,128L960,64L1440,64L1440,320L960,320L480,320L0,320Z"></path></svg>
      </div>
    </div>
  </div>
</section>
   
   </>
}