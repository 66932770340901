import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "@fortawesome/fontawesome-free/css/all.css";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import './App.css';
import './index.css'; 

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SignIn from './components/auth/signin/signin';
import SignUp from './components/auth/signup/signup';

import { Layout } from './components/layout/layout';
import Home from './components/home/home';
import { RoyalRegency } from './components/hotels/royalRegency/royalRegency';
import { PlazaAquaPark } from './components/hotels/plazaAquapark/plazaAquepark';
import { RomanceRegencyClub } from './components/hotels/romanceRegencyClub/romanceRegencyClub';
import About from './components/about/about';
import AccountFlow from './components/accountFlow/AccountFlow';
import BookRoom from './booking/bookRoom';
import DateRange from './booking/dateRange';
import MainRestaurants from './components/facilites/resturents/main';
import SingleRestaurant from './components/facilites/resturents/singleResturent';
import ShoppingArea from './components/facilites/shopping-area';
import { GetAllRoomTypes } from './services/api';
import GymSpa from './components/facilites/gym-spa';
import Facilities from './components/facilites/Facilities';

let routers = createBrowserRouter([
  {
    path: "", element: <Layout />, children: [
      { index: true, element: < Home/> },
      { path: "home", element: <Home /> },
      { path: "login", element: <SignIn /> },
      { path: "signup", element: <SignUp /> },
      { path: "royal-regency-hotel/:hotelId", element: <RoyalRegency /> },
      { path: "plaza-aqua-park/:hotelId", element: <PlazaAquaPark /> },
      { path: "romance-regency-club/:hotelId", element: <RomanceRegencyClub /> },
      { path: "about-us", element: <About /> },
      { path: "account", element: <AccountFlow /> },
      { path: "book-room/:id", element: <BookRoom /> },
      { path: "DateRange", element: <DateRange /> },
      { path: "main-restaurants/:hotelId", element: <MainRestaurants /> },
      { path: "single-restaurant/:restaurantId", element: <SingleRestaurant /> },
      { path: "regency-facilities/:hotelId/:categoryId", element: <Facilities /> },
    ],
  },
]);


function App() {
  return <>
    <RouterProvider router={routers}></RouterProvider>
    </>
}

export default App;
