import React from "react";
import Slider from "react-slick";
import client from '../../../assets/contact/Ellipse 624.png'
import { useTranslation } from 'react-i18next';

function ReviewsSlider() {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots:false,
    infinite: true,
    autoplay: true,


  };
  return (
   <div className="container w-50">
         <h1 className='BigshotOneFont fs-1 px-5 pb-5'>{t("about_us_page.clients_reviews")}</h1>
    <div className="slider-container bg-white rounded">
      <Slider {...settings} >
        <div className="">
         <div className="d-flex justify-content-center pt-5">
         <img src={client} className="w-25" alt="" />
         </div>
         <p className="px-5 pb-5">Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat. Id sunt provident quo possimus impedit vel doloremque obcaecati qui ullam consectetur et ipsum omnis.</p>
        </div>
        <div className="">
         <div className="d-flex justify-content-center pt-5">
         <img src={client} className="w-25" alt="" />
         </div>
          <p className="px-5 pb-5"> Vel officiis dolor ea illo aut eligendi ullam non laudantium magnam et recusandae molestiae sit iure unde aut voluptate quaerat. Id sunt provident quo possimus impedit vel doloremque obcaecati qui ullam consectetur et ipsum omnis.</p>
        </div>
      </Slider>
    </div>
   </div>
  );
}

export default ReviewsSlider;
