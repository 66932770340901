import React, { useState } from "react";
import { useFormik } from "formik";
import SignUpSlider from "./signupSlider";
import { Link } from "react-router-dom";
import { useSignUp } from "../../../services/mutations";
import * as Yup from 'yup';
import logo from "../../../assets/Login/Regency Plaza Hotels 2.png";
import facebook from "../../../assets/Main/facebook.png";
import gmail from "../../../assets/Main/google.png";
import apple from "../../../assets/Main/apple-logo.png";

export default function SignUp() {  
  const [errorMessage, setErrorMessage] = useState(null);

const signUpMutation = useSignUp();

const handleSignUpSubmit = (data)=>{
  signUpMutation.mutate(data, {
    onError: (error) => {
      setErrorMessage(error.response.data);
      console.log(errorMessage,'errorrrrrr');
    },
  });
  console.log(data);
  
}
const validationSchema = Yup.object({
  firstName: Yup.string().required("firstName is required"),
  lastName: Yup.string().required("lastName is required"),
  phone: Yup.string().required("phone is required"),
  email: Yup.string().email("in-valid email").required("email is required"),
  password: Yup.string()
  .min(6, "Password must be at least 6 characters")
  .required("password is required"),
  cPass: Yup.string()
  .min(6, "cPassword must be at least 6 characters")
  .required("password is required")
});
  const formik = useFormik({
    initialValues: {
      firstName:'',
      lastName:'',
      email: '',
      phone: '',
      password: '',
      role: 'User'
    },
    validationSchema,
    onSubmit: handleSignUpSubmit ,
  });
  return (
    <>
      <div className="container">
        <div className="row p-5">
          <div className="col-md-1"></div>
          <div className="col-md-5 px-3 d-flex justify-content-around align-items-between ">
            <div className="">
              <div className="pb-3">
                <img src={logo} alt="regency logo" srcSet="regency logo" />
                <h1>Sign up</h1>
                <p>Let’s get you all set up so you can access your personal account.</p>
                {errorMessage?(
<div className="alert alert-danger">{errorMessage}</div>
                ):null}
              </div>
              <div>
              <form onSubmit={formik.handleSubmit} className="auth-box">
               <div className="d-flex justify-content-between">
               <div className=" user-box col-md-6 pe-2">
                  <input name="firstName" value={formik.values.firstName} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" id="firstName"/>
                  <label htmlFor="firstName">First Name</label>
                  {formik.touched.firstName && formik.errors.firstName ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.firstName}
                             </div>
                               ) : null}
                </div>
                <div className=" user-box col-md-6">
                  <input name="lastName" value={formik.values.lastName} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" id="lastName"/>
                  <label htmlFor="lastName">Last Name</label>
                  {formik.touched.lastName && formik.errors.lastName ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.lastName}
                             </div>
                               ) : null}
                </div>
               </div>
               <div className="d-flex justify-content-between">

               <div className=" user-box col-md-6 pe-2">
                <input name="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} type="email" id="email"/>
                  <label htmlFor="email">Email</label>
                  {formik.touched.email && formik.errors.email ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.email}
                             </div>
                               ) : null}
                </div>

               <div className=" user-box col-md-6">
                  <input name="phone" value={formik.values.phone} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" id="phone"/>
                  <label htmlFor="phone">Phone Number</label>

                  {formik.touched.phone && formik.errors.phone ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.phone}
                             </div>
                               ) : null}
                </div>

               </div>

                <div className="user-box">
                  <input name="password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" id="password" />
                  <label htmlFor="email">Password</label>
                {formik.touched.password && formik.errors.password ? (
                         <div className="alert alert-danger mt-2 p-2">
                         {formik.errors.password}
                          </div>
                          ) : null}
                </div>
                <div className="user-box">
                  <input name="cPass" value={formik.values.cPass} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" id="cPass" />
                  <label htmlFor="email">Confirm Password</label>
                {formik.touched.cPass && formik.errors.cPass ? (
                         <div className="alert alert-danger mt-2 p-2">
                         {formik.errors.cPass}
                          </div>
                          ) : null}
                </div>
                <div>
                  <div>
                  <div class="form-check pb-4">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label" for="flexCheckDefault">
  I agree to all the Terms and Privacy Policies</label>
</div>

                  </div>
                  <button type="submit" className="button-style w-100">Create account</button>
                </div>
                <div className="text-center pt-2">
                  <p>
                  Already have an account?
                    <Link to='/login'><span className="text-danger"> Login</span></Link>
                  </p>
                </div>
                <div>
                  <div className="word-between text-center text-muted">
                    <span className="text-muted word2"></span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="border border-info text-center py-1 me-1 cursor-pointer">
                    <img className="me-1 w-25" src={facebook} alt="" srcSet="" />
                    </div>
                    <div className="border border-info text-center py-1 me-1 cursor-pointer">
                    <img className=" w-25" src={gmail} alt="" srcSet="" />
                    </div>
                    <div className="border border-info text-center py-1 cursor-pointer">
                    <img className="w-25" src={apple} alt="" srcSet="" />
                    </div>
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
          <div className="col-md-5 ">
            <SignUpSlider />
          </div>
        </div>
      </div>
    </>
  );
}
