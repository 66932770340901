import React from "react";
import HomeSlider from "../homeSlider/home-slider";
import { Facilities } from "./facilites";
import { Comfort } from "./comfort";
import { Video } from "./video";
import { LuxuriousRooms } from "./luxuriousRooms";
import { HotelHighlights } from "./hotelHighlights";

export default function Home() {
  return<>
      <HomeSlider />
      <Facilities />
      <Comfort />
      <Video />
      <LuxuriousRooms />
      <HotelHighlights />
    </>
}

// تعالي ورايا قين
// Primary Languages: English, Arabic, Russian, Italian and Polish
