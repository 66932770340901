import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";

const DateRange = ({ onDateSelect }) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    // Pass selected dates to the parent component
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;
    onDateSelect(startDate, endDate);
  };

  return (
    <div>
      <DateRangePicker ranges={dateRange} onChange={handleSelect} />
    </div>
  );
};

export default DateRange;
