import beach from "../../assets/home/Blob 1.png";
import animation from './animation.module.css'
import homeStyle from './home.module.css'
import { useTranslation } from 'react-i18next';


export function Comfort(){
  const { t } = useTranslation();

return<>
<section className="container my-5">
  <div className="row align-items-center justify-content-center">
    <div className="col-12 col-md-6 text-center text-md-start ">
      <h1 className={`BigshotOneFont ${homeStyle.headerStyle} lh-base`}>
      {t("comfort")}
      </h1>
      <p>{t("sales")}</p>
    </div>
    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end order-first order-md-2 pb-md-0 pb-5">
      <div className={` ${homeStyle.container1}`}>
        <div className={`${homeStyle.bordered}`}>
          <img className="img-fluid" src={beach} alt="Beach" />
        </div>
        <div className={`${animation.irrelativeMovement}`}>
          <div className={animation.box}>
            <div className={animation.shape}></div>
            <div className={animation.shape}></div>
            <div className={animation.shape}></div>
            <h1 className={animation.h1Animation}></h1>
          </div>
        </div>
          <div className={animation.irrelativeMovementText}>
            <div className={animation.boxTitle}>
              <div className={animation.shape2}></div>
              <div className={animation.shape2}></div>
              <div className={animation.shape2}></div>
              <h1 className={animation.h1Animation}>
                <span className={`font-monospace ${animation.fontAnimation}`}>{t("that's the")} </span>
                <h1 className={` ${animation.fontAnimation2}`}>{t("Beach")}</h1>
              </h1>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>
</>
}