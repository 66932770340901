import React, { useEffect, useState } from 'react';
import singleRestaurant from '../../../assets/facilites/image (17).png'
import food1 from '../../../assets/facilites/image (15).png'
import food2 from '../../../assets/facilites/image (16).png'
import './ToggleNav.css'; 
import './mainResturant.css'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetRestaurant } from '../services/queries';
import { BASE_URL } from '../services/api';

const SingleRestaurant = () => {
   const{restaurantId}= useParams()
   const { i18n } = useTranslation();
   const [show,setShow]=useState(null);
   const { data: restaurant, error } = useGetRestaurant({ restaurantId, lang: i18n.language });
   if (restaurant) {
      console.log(restaurant);
    }
    if (error) {
      console.log(error);
    }
useEffect(()=>{
   if (restaurant) {
      setShow(restaurant[0])
   }
},[restaurant]);

  return (
    <div className="SingleRestaurant container">
      <div className="row mt-5 p-5 border border-primary border-3 rounded-5">
         <div className='col-md-6 rounded-5'>
            <img src={BASE_URL+show?.imageUrl} className='w-100 rounded-5' alt="regency restaurant" />
         </div>
         <div className='col-md-6 p-3'>
        <h2 className="">{show?.name}</h2>
    <p>{show?.description}</p>
         </div>
      </div>
      <div className='row pt-5'>
         {show?.images?.map((image)=>(
         <div className='col-md-2'>
            <img src={BASE_URL+image.imagePath} className='w-100' alt="" srcset="" />
            {/* <p>{image.imageName}</p> */}
         </div>
         ))}
      </div>
    </div>
  );
};

export default SingleRestaurant;
