import { useQuery } from "@tanstack/react-query";
import { getCategories, getRestaurantById, getRestaurants } from "./api";

export const useGetRestaurants = ({ hotelId, lang }) => {
  return useQuery({
    queryKey: ['useGetRestaurants', hotelId, lang], 
    queryFn: () => getRestaurants({ hotelId, lang }),
  });
};

export const useGetCategories = ({ hotelId, lang, categoryId }) => {
   return useQuery({
     queryKey: ['useGetCategories', hotelId, lang, categoryId], 
     queryFn: () => getCategories({ hotelId, lang, categoryId }),
   });
 };

 export const useGetRestaurant = ({ restaurantId, lang }) => {
   return useQuery({
     queryKey: ['useGetRestaurant', restaurantId, lang], 
     queryFn: () => getRestaurantById({ restaurantId, lang }),
   });
 };