import axios from "axios";

export const BASE_URL = 'https://regencyapi.mubasher.com.eg'
//console.log(BASE_URL);


export const _axios = axios.create({ baseURL: BASE_URL });

// Function to set the token
 export const setAuthToken = (token,_axios) => {  
  
  if (token) {
    _axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete _axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export const getRestaurants = async ({lang,hotelId}) => {
   console.log({lang,hotelId});
   
   const {data} = await _axios.get(`/api/Dining/GetDining/${lang}/${hotelId}`);
   return data;
 };

 export const getCategories = async ({lang,hotelId,categoryId}) => {
   
   const {data} = await _axios.get(`/api/Dining/GetGallary/${lang}/${hotelId}/${categoryId}`);
   return data;
 };
 export const getRestaurantById = async ({lang,restaurantId}) => {
   
   const {data} = await _axios.get(`/api/Dining/GetDiningById/${lang}/${restaurantId}`);
   return data;
 };