import React from 'react'
import logo from '../../assets/Login/Regency Plaza Hotels 2.png'
import { Link } from 'react-router-dom'
import LanguageSwitcher from '../LanguageSwitcher'
import LanguageDropdown from './languages'
export default function Navbar() {
  return <>
      <nav className="navbar navbar-expand-lg position-fixed w-100 z-index">
  <div className="container navbar-light bg-light border border-info rounded-5 px-5 mt-4">
    <div>
    <img className='' src={logo} alt="regency logo" srcSet="" />
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    </div>
    <div className="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link active" to='/'>Home</Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Explore
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><Link className="dropdown-item" to='/plaza-aqua-park/1'>Regency Plaza Aqua Park </Link></li>
            <li><Link className="dropdown-item"to='/royal-regency-hotel/2'>Royal Regency Club </Link></li>
            <li><Link className="dropdown-item"to='/romance-regency-club/3'>Romance Regency Club </Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Rooms</a>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="about-us">About us</Link>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Contact</a>
        </li>
      </ul>
    </div>
    <div className='d-flex'>
      <div className='pe-3'>
    <LanguageDropdown/>
      </div>
      <Link to='/book-room' className='btn bgPrimaryColor rounded-5 text-white'>Book Now</Link>
    </div>

  </div>
</nav>
    </>

}
