import room1 from "../../assets/home/Rectangle 10.png";
import room2 from "../../assets/home/Rectangle 10 (1).png";
import room3 from "../../assets/home/Rectangle 10 (2).png";
import homeStyle from './home.module.css'
import { useTranslation } from "react-i18next";


export function LuxuriousRooms(){
    const { t } = useTranslation();

   return<>
         <section className={ `${homeStyle.bgHotelRoom} mb-5`}>
        <div className={ `p-md-5 py-5 text-center ${homeStyle.layer}`}>
            <div className='d-flex justify-content-center'>
              <div>
            <h2 className="text-uppercase border-bottom border-primary pb-2">{t("Luxurious Rooms")}</h2>
            <p className='pt-3'>{t("All rooms are design for your comfort")}</p>
              </div>
            </div>
        <div className="container">
        <div className="row align-items-center justify-content-center">
            <div className="col-md-6 col-xl-4">
                <div className="pt-5 pb-3 m-3 bg-white border rounded-3">
                    <img className="w-75 border rounded-3" src={room1} alt="room1" />
                    <p className="pt-3 px-5">Television set, Extra sheets, Breakfast, and fireplace</p>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
            <div className="pt-5 pb-3 m-3 bg-white border rounded-3">
                    <img className="w-75 border rounded-3" src={room2} alt="room1" />
                    <p className="pt-3 px-5">Television set, Extra sheets, Breakfast, and fireplace</p>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
            <div className="pt-5 pb-3 m-3 bg-white border rounded-3">
                    <img className="w-75 border rounded-3" src={room3} alt="room1" />
                    <p className="pt-3 px-5">Television set, Extra sheets, Breakfast, and fireplace</p>
                </div>
            </div>
        </div>
        </div>
        
        </div>
      </section>
   </>
}
