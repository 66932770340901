import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signIn,signUp,GetAllRoomTypes, roomSearch } from "./api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setRooms } from '../store/actions/roomActions';
export function useLogin(){
  const navigate = useNavigate()
  const queryClient = useQueryClient()
   return useMutation({
       mutationFn: (data)=> signIn(data),
       onMutate: ()=>{
         console.log('mutate');
       },
       onError: ()=>{
         console.log('error');
       },
       onSuccess: (response)=>{
         console.log('success',response);
       },
       onSettled:async (response,error)=>{
         console.log('settled');
        if (error) {
          console.log(error);
        }else{
          console.log(response);
          await queryClient.invalidateQueries({queryKey:['signIn']})
          navigate('/home')
        }
       },
   })
}

export function useSignUp(){
  const navigate = useNavigate()


  const queryClient = useQueryClient()
  return useMutation({
      mutationFn:(data)=> signUp(data),

      onMutate: ()=>{
        console.log('mutate');
      },
      onError: ()=>{
        console.log('error');
      },
      onSuccess: (response)=>{
        console.log('success',response);
      },
      onSettled:async (_,error)=>{
        console.log('settled');
       if (error) {
         console.log(error);
       }else{
        console.log('dsdsds');
         await queryClient.invalidateQueries({queryKey:['signUp']})
         navigate('/login')
       }
      },
  })
}
export function useRoomSearch(){
  const dispatch = useDispatch();
  return useMutation({
      mutationFn:(data)=> roomSearch(data),
      onMutate: ()=>{
        console.log('mutate');
      },
      onSuccess: (data) => {
      //  console.log('Room search successful:', data.data);
        dispatch(setRooms(data.data));
      },
      onError: (error) => {
        console.error('Room search failed:', error);
      }
  })
}





// export function GetRoomTypes(){
//   return useMutation({
//       mutationFn:()=> GetAllRoomTypes(),

//       onMutate: ()=>{
//         console.log('mutate');
//       },
//       onError: ()=>{
//         console.log('error');
//       },
//       onSuccess: (response)=>{
//         console.log('success',response);
//       },
//       onSettled:async (_,error)=>{
//         console.log('settled');
//        if (error) {
//          console.log(error);
//        }else{
//         console.log('dsdsds');
//         //  await queryClient.invalidateQueries({queryKey:['signUp']})
//        }
//       },
//   })
// }