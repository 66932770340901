import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from "axios";
import { BASE_URL } from "../../../services/api";
import {
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import logo from "../../../assets/Login/Regency Plaza Hotels 2.png";
import LoginSlider from "./signinSlider";
import facebook from "../../../assets/Main/facebook.png";
import gmail from "../../../assets/Main/google.png";
import apple from "../../../assets/Main/apple-logo.png";
import { useProducts } from "../../../services/queries";
import { useLogin } from "../../../services/mutations";

export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState(null);

  const logInMutation = useLogin();
const handleLogInSubmit = (data)=>{
  logInMutation.mutate(data, {
    onError: (error) => {
      setErrorMessage(error.response.data);
      console.log(errorMessage);
    },
  })
}
const validationSchema = Yup.object({
  email: Yup.string().email("in-valid email").required("email is required"),
  password: Yup.string()
  .min(6, "Password must be at least 6 characters")
  .required("password is required")
});
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: handleLogInSubmit ,
  });
  // function login(values){
  //   console.log(values);
  //   return axios.post(`${BASE_URL}/api/Auth/login`,values)
  // }
  // if (products.isPending) {
  //   return <span>loading.............</span>
  // }
  // if (products.isError) {
  //   console.log(products.error);
  //   return <span>there is an error!</span>
  // }
  return (
    <>
    {/* <p>{products.fetchStatus}</p>
    {products.data.map((product,index)=>(
      <div key={index}>{product.code}</div>
    ))} */}
      <div className="container">
        <div className="row p-5">
          <div className="col-md-1"></div>
          <div className="col-md-5 px-3 d-flex justify-content-around align-items-between ">
            <div className="">
              <div className="pb-3">
                <img src={logo} alt="regency logo" srcSet="regency logo" />
                <h1>Login</h1>
                <p>Login to access your Globe account</p>
              </div>
              <div>
              <form onSubmit={formik.handleSubmit} className="auth-box">
                <div className=" user-box">
                  <input name="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} type="email" id="email"/>
                  <label htmlFor="email">Email</label>
                  {formik.touched.email && formik.errors.email ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.email}
                             </div>
                               ) : null}
                </div>

                <div className="user-box">
                  <input name="password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" id="password" />
                  <label htmlFor="email">Password</label>
                {formik.touched.password && formik.errors.password ? (
                         <div className="alert alert-danger mt-2 p-2">
                         {formik.errors.password}
                          </div>
                          ) : null}
                </div>
                <div>
                  <button type="submit" className="button-style w-100">Login</button>
                </div>
                <div className="text-center pt-2">
                  <p>
                    Don't have an account?
                    <span className="text-danger"> Sign up</span>
                  </p>
                </div>
                <div>
                  <div className="word-between text-center text-muted">
                    <span className="text-muted word"></span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="border border-info text-center py-1 me-1 cursor-pointer">
                    <img className="me-1 w-25" src={facebook} alt="" srcSet="" />
                    </div>
                    <div className="border border-info text-center py-1 me-1 cursor-pointer">
                    <img className=" w-25" src={gmail} alt="" srcSet="" />
                    </div>
                    <div className="border border-info text-center py-1 cursor-pointer">
                    <img className="w-25" src={apple} alt="" srcSet="" />
                    </div>
                  </div>
                </div>
              </form>
              </div>
            </div>
          </div>
          <div className="col-md-5 ">
            <LoginSlider />
          </div>
        </div>
      </div>
    </>
  );
}
